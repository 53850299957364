import React, { memo, useCallback, useEffect, useRef, useState } from 'react';
import { View, Text, TouchableOpacity, StyleSheet, StatusBar, Dimensions, ActivityIndicator, FlatList } from 'react-native-web';
import { connect, useDispatch, useSelector } from "react-redux";
import { Input, message, Modal, Radio, Spin, Table } from 'antd';
import { fetchPharmcyFromApi } from '../../../shared/actions/GetPharmacyActions'
import { fetchAllMedsFromApi } from "../../../shared/actions/AllMedicationActions";
import LongMedicationHandler from "../../components/LongMedicationHandler";
import { GetAppointmentDate, GetMonthName, GetYear } from '../../../shared/core/datehelper'
import { fetchRecentMedsFromApi } from '../../../shared/actions/RecentMedAction';
import getUserId from '../../../shared/core/GetToken';
import Settings from '../../../shared/config/Settings';
import { Select, notification, Checkbox, Button } from 'antd';

import GetAppSettings from '../../../shared/core/GetAppSettings';
import PrintOrganizationHeader from '../../components/PrintOrganizationHeader';
import { useReactToPrint } from 'react-to-print';
import Tooltip from 'antd/es/tooltip';
import { fetchApplicationSettingFromApi } from '../../../shared/actions/GetApplicationSettingAction';
import { isMobile } from 'react-device-detect';
import { actionAPI } from '../../../shared/reduxToolkit/actionAPI';
import StatusMessages, { useMessages } from '../../components/StatusMessages';
import CreditCardInput from 'react-credit-card-input';
import InfiniteScroll from 'react-infinite-scroll-component';
import { flushSync } from 'react-dom';


let array = [];
const { Option, OptGroup } = Select;
const LongTermMed = props => {

    const { AllMedeications, AllMedeicationsTotalData, isGetting } = useSelector(state => state.AllMedications);
    const { isAvailable } = useSelector(state => state.AppSettings);
    const { Pharmacy } = useSelector(state => state.Pharmacies)
    const { Practise } = useSelector(state => state.PracticeOrganization);
    const { Profile } = useSelector(state => state.UserProfile);
    const { Balance } = useSelector(state => state.UserAccountBalance);
    const [pageSize, setPageSize] = useState(100);           //state for the page number
    const [pageNumber, setPageNumber] = useState(1);           //state for the page number
    const [list, setList] = useState([])
    const [repeadtedmodalVisible, setrepeadtedmodalVisible] = useState(false);
    const [portalSetting, setPortalSetting] = useState(null);
    /////////////////////
    const [longTermMedsDataList, setLongTermMedsDataList] = useState([]);
    const [isSelected, setIsSelected] = useState(false);
    const [Pharmacyname, setPharmacyName] = useState({ id: '', name: 'Select Pharmacy' })
    const [isPickupSelected, setIsPickupSelected] = useState(false);
    const [isPharmacySelected, setIsPharmacySelected] = useState(false);
    const [loading, setLoading] = useState(false);
    const [isReady, setIsReady] = useState(false);
    const [ordertype, setOrderType] = useState('Select');
    const [ready, setReady] = useState(false);

    const [isSelectedBtnActive, setIsSelectedBtnActive] = useState(false)
    const [isSelectAllMedEnable, setIsSelectAllMedEnable] = useState(false)

    const [printtMedsModal, setPrintMedsModal] = useState(false)
    const [isPrintActive, setIsPrintActive] = useState(false)
    const [providerLeaveChoice, setProviderLeaveChoice] = useState("default")
    const [prefferedPharmacyID, setPrefferedPharmacyID] = useState("")
    const [repeatMedNote, setRepeatMedNote] = useState("")
    const [isRepeatFavChecked, setIsRepeatFavChecked] = useState(false)
    const [selectedMedsList, setselectedMedsList] = useState([])
    const [isMedDetailModalOpen, setIsMedDetailModalOpen] = useState(false)
    const [openedMedData, setOpenedMedData] = useState(null)
    const [PharmaciesRegions, setpharmaciesRegions] = useState([])
    const [paymentMethodType, setPaymentMethodType] = useState("2");
    const [lookupData, setLookupData] = useState(null);
    const [paymentConfigurationError, setPaymentConfigurationError] = useState(null);
    const [cardtypename, setCardTypeName] = useState("");
    const [cardTypeErrMsg, setCardTypeErrMsg] = useState("");
    const [cardTypeNotMatchErrMsg, setCardTypeNotMatchErrMsg] = useState("")
    const [windcaveCardDetails, setWindcaveCardDetails] = useState({
        cardNumber: "",
        cardCVC: "",
        cardExpiry: "",
        holderName: ""
    })
    const [windcaveCardDetailsErrState, setWindcaveCardDetailsErrState] = useState({
        cardNumber: "",
        cardCVC: "",
        cardExpiry: "",
        holderName: ""
    })
    const [practiceCode, setPracticeCode] = useState(0)
    const [nhiNumber, setNhiNumber] = useState("")
    const [userEmail, setUserEmail] = useState("")
    const [navigateUrlForPoli, setNavigateUrlForPoli] = useState("")
    const [poliPaymentModalVisible, setPoliPaymentModalVisible] = useState(false)
    const [accountBalanceType, setAccountBalanceType] = useState(null)
    const [filteredBalance, setFilteredBalance] = useState(0)
    const [activePaymentOption, setActivePaymentOption] = useState("none")
    const [cardkindName, setCardkindName] = useState("");
    const [medRepeatPaymentNotes, setMedRepeatPaymentNotes] = useState("");
    const [prescriptionFee, setPrescriptionFee] = useState(0);
    const [gettingPrescriptionFee, setGettingPrescriptionFee] = useState(false);
    const [payableFeeBeforeDeductions, setPayableFeeBeforeDeductions] = useState(0);

    const [validationErrorMsgs, setValidationErrorMsgs] = useState({
        amountErr: "Minimum amount of $1 is required",
        cardNumberCompleteError: "Please enter correct card number",
        cardTypeErrMsg: "Please select a Card Type",
        onceValidated: false
    })
    const [deductedAmount, setDeductedAmount] = useState(0);
    const [isWindcavePaymentModalOpen, setIsWindcavePaymentModalOpen] = useState(false);
    const [windcavePaymentURL, setWindcavePaymentURL] = useState(false);
    const [totalRecords, setTotalRecords] = useState(10);
    const [ignoreLeave, setIgnoreLeave] = useState(false)

    const [messages, addMessage] = useMessages();
    const apiDispatcher = useDispatch()
    const openNotificationWithIcon = (type, value, msg = "Repeat Medication Request") => {
        notification[type]({
            message: msg,
            description:
                value,
        });
    };
    let limit = 15
    let offset = 1

    function capitalize(word) {
        if (word && word[0] !== "") {
            return word[0]?.toUpperCase() + word?.slice(1)?.toLowerCase();
        } else {
            return ""
        }
    }
    const getAllLongTermMedsDataFromApi = (fromStart = false) => {
        limit = 15;
        offset = fromStart ? 1 : Math.ceil(longTermMedsDataList.length / limit) + 1;
        setPageNumber(offset)
        if (offset == 1) {
            flushSync(() => {
                setLongTermMedsDataList([])
                setTotalRecords(0)
            })
        }

        apiDispatcher(actionAPI.GetAllMedicationsAPI({ pageSize: limit, pageNumber: offset }))

    };
    const fetchMoreLongTermMedsDataFromAPI = (ignore = false) => {
        if (ignore || longTermMedsDataList.length < totalRecords) {
            getAllLongTermMedsDataFromApi();
        }
    }

    useEffect(() => {
        getAllLongTermMedsDataFromApi(true)
    }, [props.longTermClickChange])
    useEffect(() => {
        if (AllMedeications !== "undefined" && AllMedeications?.length > 0) {
            setTotalRecords(AllMedeicationsTotalData)
            // let isAlreadyAdded = false
            // longTermMedsDataList.map(medStateData => {
            //     let checkingArr = AllMedeications.filter(medFromApi =>
            //         medFromApi?.resource?.id === medStateData?.resource?.id
            //     )
            //     if (checkingArr.length > 0) {
            //         isAlreadyAdded = true
            //     }
            // })
            if (AllMedeications && longTermMedsDataList != AllMedeications && pageNumber > 1) {
                const mergeData = [...longTermMedsDataList, ...AllMedeications];
                setLongTermMedsDataList(mergeData);
            }
            else if (pageNumber === 1) {
                setLongTermMedsDataList(AllMedeications);

            }

        }
    }, [AllMedeications])
    useEffect(() => {
        function parseQuery(queryString) {
            const params = {};
            const queryStringWithoutQuestionMark = queryString.substring(1);
            const queryParams = queryStringWithoutQuestionMark.split("&");
            queryParams.forEach((param) => {
                const [key, value] = param.split("=");
                params[key] = decodeURIComponent(value);
            });
            return params;
        }


        const queryString = window.location.search;
        let { RepeatSuccess } = parseQuery(queryString);
        // debugger
        if (RepeatSuccess === "True" && (localStorage.getItem("redirectedOnce") === "false")) {
            // setNewModalVissible(true)
            // setMessageState('Your payment is successful and your prescription has been sent to the practice. You can check the status of your prescription under Rx Request(s).')
            localStorage.setItem("redirectedOnce", true)
            props.setActiveTabKey("3")
            apiDispatcher(actionAPI.GetAppSettingAPI())
            apiDispatcher(actionAPI.GetAccountBalanceAPI())

        }
        // alert('Called Future')
        // apiDispatcher(actionAPI.GetAllMedicationsAPI({ pageSize: pageSize, pageNumber: pageNumber }))

        apiDispatcher(actionAPI.GetPharmaciesDataFromAPI())
        apiDispatcher(actionAPI.GetAccountBalanceAPI())
        // array = []
        setselectedMedsList([])

    }, [])
    useEffect(() => {
        if (typeof (Practise) !== 'undefined' && Practise.length) {
            // item.resource.extension.find(o => o.url === 'ObservationSummary')?.valueString
            setPracticeCode(Practise[0]?.resource?.extension?.find(item => item?.url === "Practice Code")?.valueString)

        }
    }, [Practise])
    useEffect(() => {
        if (typeof (Profile) !== 'undefined' && Profile.length) {
            setNhiNumber(Profile[0]?.resource?.extension[3]?.valueString ? Profile[0]?.resource?.extension[3]?.valueString : "")
            setUserEmail(Profile[0]?.resource?.telecom.find(x => x.system == "email")?.value)
        }
    }, [Profile])
    const getPrescriptionFeeFromApi = (pIsUrgentPrescription, pIsUrgentFax, pIsFaxPrescription) => {
        setGettingPrescriptionFee(true)
        getUserId().then((userInfo) => {
            const item = JSON.parse(userInfo);

            var myHeaders = new Headers();
            myHeaders.append("Token", item.token)
            myHeaders.append("Content-Type", "application/json")
            var raw = {
                PatientID: item.userid,
                IsFaxPrescription: pIsFaxPrescription,
                IsUrgentPrescription: pIsUrgentPrescription,
                IsUrgentFax: pIsUrgentFax,
                PageSize: 10,
                PageNumber: 1,
            }
            var requestOptions = {
                method: 'POST',
                headers: myHeaders,
                redirect: 'follow',
                body: JSON.stringify(raw)
            };
            fetch(Settings.apiUrl + "Patient/GetPrescriptionFee", requestOptions)
                .then(response => response.json())
                .then(result => {
                    // debugger
                    setGettingPrescriptionFee(false)
                    if (parseFloat(result) || parseFloat(result) === 0) {
                        // openNotificationWithIcon("success", "Prescription Fee: $" + parseFloat(result))
                        setPrescriptionFee(parseFloat(result))

                    }
                })
                .catch(error => {

                });

        })
    }

    useEffect(() => {
        if ((portalSetting && portalSetting?.EnableRepeatRxInvoicing) && (ordertype === "Pick up" || ordertype === "Pharmacy")) {

            let pIsUrgentPrescription = 0
            let pIsUrgentFax = 0
            let pIsFaxPrescription = 0

            if (ordertype === "Pick up" && isSelected) {
                pIsUrgentPrescription = 1

            }
            else if (ordertype === "Pharmacy" && isSelected) {
                pIsUrgentFax = 1
            }
            else if (ordertype === "Pick up" && !isSelected) {
                pIsFaxPrescription = 0
            }
            else if (ordertype === "Pharmacy" && !isSelected) {
                pIsFaxPrescription = 1
            }
            else {
                pIsUrgentPrescription = 0
                pIsUrgentFax = 0
                pIsFaxPrescription = 0
            }
            getPrescriptionFeeFromApi(pIsUrgentPrescription, pIsUrgentFax, pIsFaxPrescription)
        }
        else {
            setPrescriptionFee(0)
        }

    }, [ordertype, isSelected])
    const hanldeSlectedList = (value) => {
        // debugger
        let abc = false;
        let obj = {
            "resource": value
        }
        if (selectedMedsList.length == 0) {
            // array = [...array, value]
            setselectedMedsList([...selectedMedsList, obj])
        } else {
            let arr = selectedMedsList
            selectedMedsList.map((item, index) => {
                if (item.resource == obj.resource) {
                    arr = arr.filter(arrItem => arrItem?.resource !== obj?.resource)
                    abc = true
                }
            })
            if (!abc) {
                arr = [...arr, obj]
            }
            setselectedMedsList(arr)
        }
        setIsReady(!isReady)
    }
    const handleSelectAllMed = () => {
        if (isSelectedBtnActive) {
            setselectedMedsList(longTermMedsDataList?.filter(item => item?.resource?.extension?.find(o => o?.url === "IsNzulmActive")?.valueBoolean))
            // array = [...AllMedeications]
            setIsSelectAllMedEnable(true)
        } else {
            // array = []
            setselectedMedsList([])
            setIsSelectAllMedEnable(false)
        }
    }
    useEffect(() => {
        handleSelectAllMed()
    }, [isSelectedBtnActive])
    useEffect(() => {
        console.log("selected meds changed", selectedMedsList)
    }, [selectedMedsList])

    const repeadtedmedicationHandler = () => {



        getUserId().then((userInfo) => {
            const item = JSON.parse(userInfo);
            let obj = [];
            let medcnlist = ''
            selectedMedsList.map((item, index) => {
                medcnlist = medcnlist + item.resource.medicationCodeableConcept.coding[0].display + ','
                let ob = {
                    "MedicationRepeatRequestID": 0,
                    "MessageID": 0,
                    "MedicationID": item.resource.id,
                    "SCTID": null,
                    "Type": null,
                    "PracticeName": null,
                    "FullySpecifiedName": item.resource.medicationCodeableConcept.coding[0].display,
                    "MedicineID": 0,
                    "Form": null,
                    "MedicineName": null,
                    "Take": null,
                    "Duration": null,
                    "StartDate": null,
                    "ProviderID": 0,
                    "Directions": item.resource.dosageInstruction[0].text,
                    "IsLongTerm": item.resource.extension[0].valueBoolean,
                    "IsActive": false,
                    "IsDeleted": false,
                    "InsertedBy": 2232675,
                    "UpdatedBy": 0,
                    "InsertedAt": null,
                    "UpdatedAt": null,
                    "TotalRecords": 0,
                    "PracticeID": 0,
                    "PatientID": 0,
                    "Comments": repeatMedNote,
                    "Quantity": null,
                    "ProviderName": null,
                    "PharmacyId": Pharmacyname.id,
                    "PickupTypeId": 2,
                    "OtherPharmacy": null,
                    "PrefPharmacyId": Pharmacyname.id,
                    "IsUrgent": isSelected,
                    "PharmacyLocationId": 0
                };
                obj.push(ob)
            })

            let raw = {
                "extension": [
                    {
                        "url": "MessageBody",
                        "valueString": isPharmacySelected ? "Notes:Type: Pharmacy Pharmacy on :" + Pharmacyname.name + "Requested Medicine:" + medcnlist : "Notes:Type: Pickup Pharmacy on :" + Pharmacyname.name + "Requested Medicine:" + medcnlist
                        // "valueString": "Notes:Type: Pharmacy \n Pharmacy on :AKL Pharmacys \n Requested Medicine:Depo-Testosterone (testosterone cipionate 1 g/10 mL) injection: solution, vial"
                    },
                    {
                        "url": "MessageType",
                        "valueString": "Send Mail"
                    },
                    {
                        "url": "MedicationList",
                        "valueString": JSON.stringify(obj),

                    }
                ],
                "note": [
                    {
                        "text": "Repeat Rx Request"
                    }
                ],
                "recipient": [
                    {
                        "display": Practise && Practise.length ? Practise[0].resource.extension[2].valueString : "N/A",
                        "reference": Practise && Practise.length ? "Practitioner/" + Practise[0].resource.extension[3].valueString : "N/A",//"/284389"
                    }
                ],
                "resourceType": "Communication",
                "sender": {
                    "display": item.username,
                    "reference": "Patient/" + item.userid
                },
                "status": "completed"
            }

            var myHeaders = new Headers();
            myHeaders.append("Token", item.token);
            myHeaders.append("Content-Type", "application/json");
            var requestOptions = {
                method: 'POST',
                headers: myHeaders,
                body: JSON.stringify(raw),
                redirect: 'follow'
            };
            fetch(Settings.apiUrl + "Patient/Message?RoleID=1&CallType=3", requestOptions)
                .then(response => response.json())
                .then(result => {

                    // array = [];
                    setselectedMedsList([])
                    setLoading(false)
                    openNotificationWithIcon('success')
                    handleRepeatMedCancel()

                    setTimeout(() => {
                        window.location.reload()
                    }, 1500);

                })
                .catch(error => {
                    setLoading(false)
                });
        })

    }

    const [response, setResponse] = useState(false);

    const [newModalVissible, setNewModalVissible] = useState(false)
    const [paymentConfirmationModalOpen, setPaymentConfirmationModalOpen] = useState(false)
    const [providerOnLeaveModalVissible, setProviderOnLeaveModalVissible] = useState(false)
    const [mesagestate, setMessageState] = useState('')
    const [providerOnLeaveApiData, setProviderOnLeaveApiData] = useState([])
    const [providerOnLeaveSelect, setProviderOnLeaveSelect] = useState()

    const RepeatedMedicationAdding = (ignoreLeaveOverride = ignoreLeave, isInvoicingBit = false, invoiceAmountBit = 0) => {
    //    debugger;
        let idString = ''

        getUserId().then(userInfo => {
            const item = JSON.parse(userInfo);
            var myHeaders = new Headers();
            setLoading(true)
            myHeaders.append("Token", item.token);
            myHeaders.append("Content-Type", "application/json");
            const defaultGPID = Practise && Practise.length && Practise[0].resource.extension.find(o => o.url === 'GP ID')?.valueString ? `${Practise[0].resource.extension.find(o => o.url === 'GP ID')?.valueString}` : " N/A"
            selectedMedsList.map((item, i) => {
                idString = idString + item.resource?.id + ","
            })
            let uniqueIds = [...new Set(idString.split(',').map(id => id.trim()))];
            let result = uniqueIds.join(', ');
            idString = result?.trim()
            let pIsUrgentPrescription = 0
            let pIsUrgentFax = 0
            let pIsFaxPrescription = 0

            if (ordertype === "Pick up" && isSelected) {
                pIsUrgentPrescription = 1

            }
            else if (ordertype === "Pharmacy" && isSelected) {
                pIsUrgentFax = 1
            }
            else if (ordertype === "Pick up" && !isSelected) {
                pIsFaxPrescription = 0
            }
            else if (ordertype === "Pharmacy" && !isSelected) {
                pIsFaxPrescription = 1
            }
            else {
                pIsUrgentPrescription = 0
                pIsUrgentFax = 0
                pIsFaxPrescription = 0
            }
            var raw;
            {
                isPickupSelected ?
                    raw = {
                        "PatientID": item.userid,
                        "MedicationID": idString,
                        "PickUpTypeID": 1,
                        "PharmacyID": 0,
                        "PharmacyName": "",
                        "IsUrgent": isSelected ? true : false,
                        "IsPreferredPharmacy": false,
                        "Notes": repeatMedNote,
                        "PharmacyLocationId": 0,
                        "ProviderId": providerLeaveChoice === "default" ? defaultGPID : providerOnLeaveSelect,      
                    }
                    :
                    raw = {
                        "PatientID": item.userid,
                        "MedicationID": idString,
                        "PickUpTypeID": 2,
                        "PharmacyID": Pharmacyname.id,
                        "PharmacyName": Pharmacyname.name,
                        "IsUrgent": isSelected ? true : false,
                        "IsPreferredPharmacy": isRepeatFavChecked,
                        "Notes": repeatMedNote,
                        "PharmacyLocationId": 0,
                        "IgnoreLeave": ignoreLeaveOverride,
                        "ProviderId": providerLeaveChoice === "default" ? defaultGPID : providerOnLeaveSelect,      
                    }


            }
            if (isInvoicingBit) {

                raw = {
                    ...raw,
                    "IsInvoice": isInvoicingBit,
                    "InvoiceAmount": invoiceAmountBit,
                    "PrescriptionFeeAmount": invoiceAmountBit,
                    "PaymentLog": getLogNotesStr()


                }
            } else {
                raw = {
                    ...raw,
                    "IsInvoice": false,
                    "InvoiceAmount": null
                }

            }
            if (portalSetting && portalSetting?.EnableRepeatRxInvoicing) {
                raw = {
                    ...raw,
                    "IsRxInvoicing": true,
                    "IsFaxPrescription": pIsFaxPrescription,
                    "IsUrgentPrescription": pIsUrgentPrescription,
                    "IsUrgentFax": pIsUrgentFax,
                }
            } else {
                raw = {
                    ...raw,
                    "IsRxInvoicing": false,
                    "IsFaxPrescription": pIsFaxPrescription,
                    "IsUrgentPrescription": pIsUrgentPrescription,
                    "IsUrgentFax": pIsUrgentFax,
                }

            }
            raw = JSON.stringify(raw)
            var requestOptions = {
                method: 'POST',
                headers: myHeaders,
                body: raw,
                redirect: 'follow'
            };
            let statusMed
            fetch(Settings.apiUrl + "Patient/SendRepeatMedicationRequest", requestOptions)
                .then(response => {
                    statusMed = response.status
                    return response.json()
                })
                .then((result) => {

                    if (statusMed == 302) {
                        let res = JSON.parse(result)
                        setProviderOnLeaveApiData(res)
                        setProviderLeaveChoice("other")
                        setProviderOnLeaveModalVissible(true)
                        if (res.length > 0) {
                            setProviderOnLeaveSelect(res[0]?.ProviderID)
                        }
                        handleRepeatMedCancel(false)

                    } else {
                        if (typeof result === 'string') {
                            setProviderLeaveChoice("default")
                            setProviderOnLeaveSelect()
                            setIgnoreLeave(false)
                            if (result.indexOf("successfully") >= 0 && isInvoicingBit) {
                                // setNewModalVissible(true)
                                // setMessageState("Your payment is successful and your prescription has been sent to the practice. You can check the status of your prescription under Rx Request(s).")
                                localStorage.setItem("redirectedOnce", true)
                                props.setActiveTabKey("3")
                                apiDispatcher(actionAPI.GetAppSettingAPI())
                                apiDispatcher(actionAPI.GetAccountBalanceAPI())

                            } else {
                                setNewModalVissible(true)
                                setMessageState(result)
                            }
                        } else if (result.Message === "An error has occurred.") {
                            setNewModalVissible(true)
                            setMessageState("Unable to repeat Medication")
                        }
                        setselectedMedsList([])
                        handleRepeatMedCancel(true)
                    }

                })
                .catch(error => {
                    setLoading(false)
                    //  //{}
                }).finally(() => {
                    setLoading(false);
                });
        })



    }

    function handleChange(value, obj) {
        setPharmacyName({ id: value, name: obj.children });
    }
    function handleChangetype(e) {

        if (e?.target?.value == "Pick up") {
            setIsPickupSelected(true)
        } else {

            setIsPickupSelected(false)
        }
        setOrderType(e?.target?.value)
    }
    const selectAllMeds = () => {
        setIsSelectedBtnActive(!isSelectedBtnActive)

    }
    const handleRepeatMedCancel = (clearNotes = true) => {
        setrepeadtedmodalVisible(false)
        if (clearNotes) {
            setPrescriptionFee(0)
            setIsSelected(false)
            setIsRepeatFavChecked(false)
            setPharmacyName({ id: (portalSetting.PharmacyId && portalSetting.PharmacyId != "0" ? portalSetting.PharmacyId : ""), name: (portalSetting.PrefPharmacy && portalSetting.PrefPharmacy != "N/A" ? portalSetting.PrefPharmacy : "Select Pharmacy") })
            setRepeatMedNote("")

            setFilteredBalance(0)
            setActivePaymentOption("none")
            setAccountBalanceType(null)
            setPayableFeeBeforeDeductions(0)
            apiDispatcher(actionAPI.GetAccountBalanceAPI())
        }
    }
    const [api, contextHolder] = notification.useNotification();
    const openNotification = (placement, message) => {
        api.info({
            message: `${message}`,

            placement,
            style: {
                color: "#fff",
                fontWeight: "bold",

            },
        });
    };
    const getStripeConfiguration = () => {
        getUserId().then((userInfo) => {


            const item = JSON.parse(userInfo);

            var myHeaders = new Headers();

            myHeaders.append("Token", item.token)
            myHeaders.append("Content-Type", "application/json")

            var requestOptions = {
                method: 'POST',
                headers: myHeaders,
                redirect: 'follow',
            };

            fetch(Settings.apiUrl + "Patient/LoadPaymentConfiguration?PatientID=" + item.userid, requestOptions)
                .then(data => data.json())
                .then(json => {
                    const stripeConfiguration = json?.resource?.extension[4]?.valueString
                    const paymentExpressConfiguration = json?.resource?.extension[3]?.valueString
                    const poliConfiguration = json?.resource?.extension[5]?.valueString
                    if (!stripeConfiguration && !poliConfiguration && !paymentExpressConfiguration) {
                        setPaymentConfigurationError(true)

                    }
                })
                .catch(error => {

                })
                .finally(() => {


                });
        });
    }
    useEffect(() => {

        GetAppSettings().then((info) => {

            const set = JSON.parse(info)
            setPaymentMethodType(set.PaymentMethodType ? set.PaymentMethodType : "2")
            setLookupData(set.PaymentCardTypes && JSON.parse(set.PaymentCardTypes))
        })
        getStripeConfiguration()
    }, [])
    const windcaveCreditRef = useRef()
    const cardRef = useRef()

    const HandleCardChangeElement = (e) => {
        console.log("cardkind changed =>", e)
        if (e.brand && e.brand != "") {

            if (e.brand == "amex") {
                setCardkindName("americanexpress")

            }
            else {
                setCardkindName(e.brand)
            }
        }
        if (e.empty)
            setValidationErrorMsgs({ ...validationErrorMsgs, cardNumberCompleteError: "Card number is required" })
        if (!e.complete)
            setValidationErrorMsgs({ ...validationErrorMsgs, cardNumberCompleteError: "Your card number is incomplete." })

        if (!e.empty)
            setValidationErrorMsgs({ ...validationErrorMsgs, cardNumberCompleteError: e?.error?.message })

        if (!e.complete && e.error === undefined)
            setValidationErrorMsgs({ ...validationErrorMsgs, cardNumberCompleteError: "CVC is required." })
        if (!e.empty && e.complete) {
            setValidationErrorMsgs({ ...validationErrorMsgs, cardNumberCompleteError: "accomplished" })
            setCardTypeNotMatchErrMsg("")
            // if (e.brand == "amex") {
            //   setCardkindName("americanexpress")

            // }
            // else {
            //   setCardkindName(e.brand)
            // }
        }
    }
    const hanlePrintMeds = () => {
        if (selectedMedsList.length === 0) {
            openNotification("topRight", "Select at least one Medicine")
        }
        else {
            setPrintMedsModal(true)
        }
    }
    const pageStyleCss = `@page {
        size: A4;
      }
      *{
          font-family: sans-serif;
        }
        .print-header{
            color: lightgrey;
          display: flex;
          justify-content: space-between;
          margin-bottom: 10px;
      }
        .print-header-left{
            display: flex;
        }
        h3,h4,h5,p{
            margin:0px;
        }
        table {
  border-collapse: collapse;
  font-size:16px;
}
        th, td {
	border: 1px solid #f2f4f9;
	padding: 8px;
}
.print-med-table{

}



.lab-test-print-header-container{
    display: flex;
    flex-direction: row;
    flex: 1 0 0;
}
.lab-test-column{
    display: flex;
    flex-direction: column;
    flex: 1 0 0;
}

.padding-left-right-ten{
    padding-left: 10px;
    padding-right: 10px;
}


table.longterm-print-table{
    border-collapse:collapse;
    cellspacing:0;
    width:100%;
}
table.longterm-print-table tr.print-med-table-row{
    font-size:13px;
    font-weight:500;
}
table.longterm-print-table tr.print-med-table-row th{
    border:1px solid #f2f4f9;
    padding:10px;
    background-color: #f2f4f9 !important;
    -webkit-print-color-adjust:exact !important;
    print-color-adjust:exact !important; 
}

table.longterm-print-table tr.print-med-table-row td{
    border:1px solid #f2f4f9;
    padding:10px;
}

.table-bottom-comment{
    border:1px solid #f2f4f9;
    border-top:0;
    padding:10px;
    background-color: #f2f4f9 !important;
    -webkit-print-color-adjust:exact !important;
    print-color-adjust:exact !important; 
font-size:13px;

}
      `;
    const cssTable = ' table td { white-space: inherit !important; }'
    const componentRef = useRef();
    const handlePrint = useReactToPrint({
        content: () => componentRef.current,
        copyStyles: false,
        pageStyle: pageStyleCss,
        onAfterPrint: () => setPrintMedsModal(false)
    })

    useEffect(() => {
        if (isPrintActive) {
            handlePrint()
            setIsPrintActive(false)
        }
    }, [isPrintActive])
    useEffect(() => {
        if (!isNaN(parseFloat(payableFeeBeforeDeductions))) {

            let [cardType, cardCharges] = cardtypename?.replace('%', '').split('-');

            console.log("cardkindName", cardkindName, ",  cardType => ", cardType, " after trim => ", cardType.replace(" ", "").toLowerCase().trim())
            var stripeElementCardType = cardkindName
            var dropDownCardType = cardType?.split(" ")?.join("")?.toLowerCase()

            if (payableFeeBeforeDeductions && parseFloat(payableFeeBeforeDeductions) > 0 && cardtypename != "" && (stripeElementCardType == dropDownCardType)) {
                setDeductedAmount((((parseFloat(payableFeeBeforeDeductions) * parseFloat(cardCharges)) / 100) + parseFloat(payableFeeBeforeDeductions)).toFixed(2))
            } else {
                setDeductedAmount((parseFloat(payableFeeBeforeDeductions) === NaN ? 0 : parseFloat(payableFeeBeforeDeductions)).toFixed(2))
            }
        }

    }, [payableFeeBeforeDeductions, cardkindName, cardtypename, prescriptionFee])
    useEffect(() => {
        if (activePaymentOption !== "none") {
            if (accountBalanceType === "Credit") {
                if (filteredBalance < prescriptionFee) {
                    setPayableFeeBeforeDeductions(prescriptionFee - filteredBalance)
                } else {
                    setPayableFeeBeforeDeductions(prescriptionFee)

                }
            } else {

                if (activePaymentOption === "DebitOption1") {
                    setPayableFeeBeforeDeductions(filteredBalance + prescriptionFee)

                } else if (activePaymentOption === "DebitOption2") {

                    setPayableFeeBeforeDeductions(prescriptionFee)
                }
            }
        }
        if (activePaymentOption && filteredBalance && prescriptionFee && accountBalanceType) {
            console.log(getLogNotesStr())
        }
    }, [prescriptionFee])
    useEffect(() => {
        GetAppSettings().then((info) => {
            const set = JSON.parse(info);
            setPortalSetting(set)
            if (set.IsRxFax) {

                setOrderType("Pharmacy")
                setIsPharmacySelected(true)
                setIsPickupSelected(false)
            } else if (set.ShowPickupTypeInRepeatRx) {

                setOrderType("Pick up")
                setIsPickupSelected(true)
                setIsPharmacySelected(false)

            } else {

                setOrderType("Select")
                setIsPickupSelected(false)
                setIsPharmacySelected(false)
            }


            if (Pharmacy && Pharmacy.length > 0 && set.PharmacyId && set.PrefPharmacy) {
                let PharmID = Pharmacy.find(Pharm => Pharm?.resource?.id === set.PharmacyId)?.resource?.id
                let PharmName = Pharmacy.find(Pharm => Pharm?.resource?.name === set.PrefPharmacy)?.resource?.name
                // debugger
                if (PharmID && PharmID != "" && PharmName && PharmName != "") {
                    setPrefferedPharmacyID(PharmID)
                    setPharmacyName({ id: (PharmID), name: (PharmName) })
                }
                else {
                    setPrefferedPharmacyID("")
                    setPharmacyName({ id: "", name: "" })
                }
            }
        })
    }, [isAvailable, Pharmacy])
    useEffect(() => {
        if (Pharmacy && Pharmacy.length > 0) {
            let arr = PharmaciesRegions
            Pharmacy.map(value => {

                if (!value.resource.extension[2].valueBoolean && !arr.includes(value.resource.extension[3].valueString)) {

                    arr.push(value.resource.extension[3].valueString)
                }
            })
            setpharmaciesRegions(arr)
        }
    }, [Pharmacy])

    const handlePayNowValidations = () => {
        let errCaught = false
        // if (paymentMethodType === '1' && windcaveCardDetails.holderName.trim() === "") {
        //     setWindcaveCardDetailsErrState({
        //         ...windcaveCardDetailsErrState,
        //         holderName: "Card holder name is mandatory"
        //     })
        //     errCaught = true
        // }
        // if (deductedAmount == "" || deductedAmount.length == 0) {
        //     openNotificationWithIcon('error', 'Your Request cannot be generated as the payment charges cannot be less than $1')
        //     setLoading(false)
        //     return;
        // }
        if (parseFloat(deductedAmount) > 0 && parseFloat(deductedAmount) < 1) {
            openNotificationWithIcon('error', 'Your Request cannot be generated as the payment charges cannot be less than $1')
            setLoading(false)
            errCaught = true
        }

        return errCaught
    }
    const handleCardNumberValidations = () => {
        let errCaught = false
        if (validationErrorMsgs.cardNumberCompleteError == "" ||
            validationErrorMsgs.cardNumberCompleteError == null ||
            validationErrorMsgs.cardNumberCompleteError == "Your card number is invalid." ||
            validationErrorMsgs.cardNumberCompleteError == "Your card number is incomplete." ||
            validationErrorMsgs.cardNumberCompleteError == "CVC is required." ||
            validationErrorMsgs.cardNumberCompleteError == 'Please enter correct card number') {
            openNotificationWithIcon("error", "Card Number is invalid")
            errCaught = true
        }
        if (validationErrorMsgs.cardNumberCompleteError == "accomplished") {
            errCaught = false;
        }
        return errCaught

    }
    const handleCardTypeValidations = () => {
        let errCaught = false

        if (cardtypename == "") {
            setCardTypeErrMsg("Please select a Card Type")
            errCaught = true
        }
        else {
            console.log("cardName", cardtypename)
        }
        return errCaught
    }

    const isCardTypeMatch = () => {
        let [cardType, cardCharges] = cardtypename?.replace('%', '').split('-');

        if (cardType && cardkindName !== cardType?.split(" ")?.join("")?.toLowerCase()) {
            openNotificationWithIcon("error", "Payment cannot be processed as selected card type does not match with card number. ")
            return false
        } else {
            return true
        }
    }
    const isWindcaveCardTypeMatch = () => {
        let [cardType, cardCharges] = cardtypename?.replace('%', '').split('-');

        if (cardType && cardkindName !== cardType?.split(" ")?.join("")?.toLowerCase()) {

            return false
        } else {
            return true
        }
    }
    const handleWindcaveCardNumberValidations = () => {
        // if (validCardDiv?.toString()?.indexOf("is-invalid")) {
        //   console.log("windcaveCreditRef.current,", windcaveCreditRef.current?.toString()?.indexOf("is-invalid"))

        // }

        if (document.getElementsByClassName("card-input-windcave")[0].classList.value.indexOf("is-invalid") >= 0 || windcaveCardDetails.cardNumber.trim() === "") {
            openNotificationWithIcon("error", "Please enter a valid Card Number")
            return true
        } else {

            return false
        }
    }
    const xmlToJson = (element) => {
        const data = {};

        if (element.nodeType === Node.ELEMENT_NODE) {
            if (element.attributes.length > 0) {
                data['attributes'] = {};
                for (let i = 0; i < element.attributes.length; i++) {
                    const attribute = element.attributes[i];
                    data['attributes'][attribute.nodeName] = attribute.nodeValue;
                }
            }
        } else if (element.nodeType === Node.TEXT_NODE) {
            data['text'] = element.nodeValue.trim();
        }

        if (element.hasChildNodes()) {
            for (let i = 0; i < element.childNodes.length; i++) {
                const child = element.childNodes[i];
                const childData = xmlToJson(child);
                if (childData !== null) {
                    if (data[child.nodeName]) {
                        if (!Array.isArray(data[child.nodeName])) {
                            data[child.nodeName] = [data[child.nodeName]];
                        }
                        data[child.nodeName].push(childData);
                    } else {
                        data[child.nodeName] = childData;
                    }
                }
            }
        }

        return data;
    }
    const calculateAmountToBePaid = () => {
        if (accountBalanceType === "Credit") {
            if (prescriptionFee > filteredBalance) {
                return parseFloat(prescriptionFee - filteredBalance).toFixed(2);
            } else {
                return 0
            }
        } else {
            if (activePaymentOption === "DebitOption1") {
                return (filteredBalance + prescriptionFee).toFixed(2)
            } else if (activePaymentOption === "DebitOption2") {
                return prescriptionFee.toFixed(2)
            }
        }
    }
    const validateRepeatRequestCall = (ignoreLeaveOverride = ignoreLeave) => {
        // setprescriptionFeeLoading(true);
        // debugger
        if (accountBalanceType === "Debit" || deductedAmount > filteredBalance) {


            if (paymentMethodType === "1") {

                let isAmountValidated = handlePayNowValidations();

                if (isAmountValidated) {
                    setLoading(false)
                    return
                }
            } else if (paymentMethodType === "2") {



                let isAmountValidated = handlePayNowValidations();
                // let isCardTypeValidated = portalSetting?.IsCardTypeConfifiguration && handleCardTypeValidations()
                // setValidationErrorMsgs({ ...validationErrorMsgs, onceValidated: true });
                // let isCardTypeNumMatch = portalSetting?.IsCardTypeConfifiguration && isCardTypeMatch()


                if (isAmountValidated) {
                    setLoading(false)
                    return
                }
            }
        }

        let idString = '';
        selectedMedsList.map((item) => {
            idString = idString + item.resource?.id + ","
        })
        let uniqueIds = [...new Set(idString.split(',').map(id => id.trim()))];
        let result = uniqueIds.join(', ');
        idString = result?.trim()
        getUserId().then((userInfo) => {
            const item = JSON.parse(userInfo);
            var myHeaders = new Headers();
            setLoading(true);
            myHeaders.append('Token', item.token);
            myHeaders.append('Content-Type', 'application/json');


            var raw = JSON.stringify({
                PatientID: item.userid,
                MedicationID: idString,
                PickUpTypeID: ordertype === "Pharmacy" ? 2 : 1,
                IgnoreLeave: ignoreLeaveOverride,
            });

            var requestOptions = {
                method: 'POST',
                headers: myHeaders,
                body: raw,
                redirect: 'follow',
            };
            let statusMed
            fetch(Settings.apiUrl + 'Patient/ValidateRepeatRequest', requestOptions)
                .then((response) => {
                    statusMed = response.status
                    return response.json()
                })
                .then((result) => {
                    if (statusMed == 302) {
                        let res = JSON.parse(result)
                        setProviderOnLeaveApiData(res)
                        setProviderLeaveChoice("other")
                        setIgnoreLeave(true)
                        setProviderOnLeaveModalVissible(true)
                        if (res.length > 0) {
                            setProviderOnLeaveSelect(res[0]?.ProviderID)
                        }
                        // handleRepeatMedCancel(false)

                    } else {
                        if (typeof result === 'string') {
                            setIgnoreLeave(false)
                            // setNewModalVissible(true)
                            // setMessageState(result)
                            if (result === "OK") {
                                // debugger
                                setPaymentConfirmationModalOpen(true)
                                // if ((accountBalanceType === "Credit" && prescriptionFee <= filteredBalance) || (parseFloat(deductedAmount) === 0)) {
                                //     const ignoreLeaveBit = false
                                //     const isInvoicingBit = true
                                //     const invoiceAmountBit = prescriptionFee
                                //     RepeatedMedicationAdding(ignoreLeaveBit, isInvoicingBit, invoiceAmountBit)
                                // } else {
                                //     let isAmountValidated = handlePayNowValidations();
                                //     if (isAmountValidated) {
                                //         return
                                //     }
                                //     // if (paymentMethodType === "2") {

                                //     //     handleStripePayment(ignoreLeave)
                                //     // } else if (paymentMethodType === "1") {
                                //     //     handleWindcaveSubmit(ignoreLeave)
                                //     // } else {
                                //     //     handlePoliSubmit(ignoreLeave)
                                //     // }
                                // }
                            } else {
                                setNewModalVissible(true)
                                setMessageState(result)
                            }
                        } else if (result.Message === "An error has occurred.") {
                            setNewModalVissible(true)
                            // handleRepeatMedCancel(true)
                            setMessageState("Unable to repeat Medication")
                        }
                    }

                })
                .catch((error) => {
                    // setLoading(false);
                    console.log('error', error);
                });
        });
    }

    const handleWindcaveSubmit = async () => {
        try {

        let isAmountValidated = handlePayNowValidations();
        let isCardTypeValidated = portalSetting?.IsCardTypeConfifiguration && handleCardTypeValidations()
        setValidationErrorMsgs({ ...validationErrorMsgs, onceValidated: true });


        if (isAmountValidated) {
            return
        }


        let isCardTypeNumMatch = portalSetting?.IsCardTypeConfifiguration && isWindcaveCardTypeMatch()

        // setIsPaying(true);
        setLoading(true)

        const itemUserInfo = await getUserId().then((userInfo) => {
            return JSON.parse(userInfo);
        });
        let [cardType, cardCharges] = cardtypename?.replace('%', '').split('-');

        let notesTemp = getLogNotesStr()

        let calculatedAmount = deductedAmount

        if (deductedAmount.toString().indexOf(".") >= 0) {
            let decimalLength = deductedAmount.toString().split(".")[1].length
            if (decimalLength === 1) {
                calculatedAmount = calculatedAmount + "0"
            }
        }
        if (true) {

            // $$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$
            let idString = '';

            const defaultGPID = Practise && Practise.length && Practise[0].resource.extension.find(o => o.url === 'GP ID')?.valueString ? `${Practise[0].resource.extension.find(o => o.url === 'GP ID')?.valueString}` : " N/A"
            selectedMedsList.map((item, i) => {
                idString = idString + item.resource?.id + ","
            })
            let uniqueIds = [...new Set(idString.split(',').map(id => id.trim()))];
            let result = uniqueIds.join(', ');
            idString = result?.trim()
            let pIsUrgentPrescription = 0
            let pIsUrgentFax = 0
            let pIsFaxPrescription = 0

            if (ordertype === "Pick up" && isSelected) {
                pIsUrgentPrescription = 1

            }
            else if (ordertype === "Pharmacy" && isSelected) {
                pIsUrgentFax = 1
            }
            else if (ordertype === "Pick up" && !isSelected) {
                pIsFaxPrescription = 0
            }
            else if (ordertype === "Pharmacy" && !isSelected) {
                pIsFaxPrescription = 1
            }
            else {
                pIsUrgentPrescription = 0
                pIsUrgentFax = 0
                pIsFaxPrescription = 0
            }

            var repeatMedPayload;
            {
                isPickupSelected ?
                    repeatMedPayload = {
                        "PatientID": itemUserInfo.userid,
                        "MedicationID": idString,
                        "PickUpTypeID": 1,
                        "PharmacyID": 0,
                        "PharmacyName": "",
                        "IsUrgent": isSelected ? true : false,
                        "IsPreferredPharmacy": false,
                        "Notes": repeatMedNote,
                        "PharmacyLocationId": 0,
                        "ProviderId": providerLeaveChoice === "default" ? defaultGPID : providerOnLeaveSelect,      

                    }
                    :
                    repeatMedPayload = {
                        "PatientID": itemUserInfo.userid,
                        "MedicationID": idString,
                        "PickUpTypeID": 2,
                        "PharmacyID": Pharmacyname.id,
                        "PharmacyName": Pharmacyname.name,
                        "IsUrgent": isSelected ? true : false,
                        "IsPreferredPharmacy": isRepeatFavChecked,
                        "Notes": repeatMedNote,
                        "PharmacyLocationId": 0,
                        "IgnoreLeave": ignoreLeave,
                        "ProviderId": providerLeaveChoice === "default" ? defaultGPID : providerOnLeaveSelect,
                    }


            }
            repeatMedPayload = {
                ...repeatMedPayload,
                "IsInvoice": true,
                "InvoiceAmount": prescriptionFee,
                "PrescriptionFeeAmount": prescriptionFee,
                "PaymentLog": getLogNotesStr()

            }

            if (portalSetting.EnableRepeatRxInvoicing) {
                repeatMedPayload = {
                    ...repeatMedPayload,
                    "IsRxInvoicing": true,
                    "IsFaxPrescription": pIsFaxPrescription,
                    "IsUrgentPrescription": pIsUrgentPrescription,
                    "IsUrgentFax": pIsUrgentFax,
                }
            }
            // $$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$

            let caughtEmail = false
            var raw = {
                "Amount": (parseFloat(calculateAmountToBePaid()).toFixed(2)),
                "PatientID": itemUserInfo.userid,
                "CardType": cardType,
                "cardCharges": cardCharges,
                "notes": 'Payment received via myindici',
                "invoiceDetailGrids": [],
                "lognotes": notesTemp,
                "paymenttype": "windcave",
                "callfrom": "web",
                "IsRxRequest": true,
                "abctick": Settings.apiUrl,
                "MerchantReference": "dsf",
                "paymentMethodType": "card",

                "MerchantData2": {
                    "key1": "patientId=" + itemUserInfo.userid + "&patientToken=" + itemUserInfo.token + "&IsAutoAdjustment=" + (activePaymentOption === "DebitOption1" ? "True" : "False"),
                    "key2": JSON.stringify(repeatMedPayload),
                },
                "UserEmail": itemUserInfo.email?.filter((email, index) => {
                    if (email && !caughtEmail) {
                        caughtEmail = true
                        return email
                    }
                })[0]
            }
            var requestOptions = {
                method: 'POST',
                headers: {
                    "Content-Type": "application/json",
                    "Token": itemUserInfo.token
                },
                body: JSON.stringify(raw),
                redirect: 'follow'
            };
            fetch(`${Settings.apiUrl}Patient/CreateWindCaveSession`, requestOptions)
                .then(res => res.json())
                .then(json => {

                    setLoading(false);


                    if (json.IsSuccess) {
                        window.open(json?.responsedata?.links[1]?.href, "_self")
                    } else {
                        if (typeof (json) === "string") {

                            openNotificationWithIcon("error", json)
                            setLoading(false)
                        } else {
                            openNotificationWithIcon("error", json.error ? json.error : "Request failed")
                            setLoading(false)

                        }

                    }
                    if (typeof (json) === "string" && json.indexOf("fails") >= 0) {
                        openNotificationWithIcon("error", "Request failed")
                        setLoading(false)
                    }

                })
                .catch(err => {
                    setLoading(false)
                    openNotificationWithIcon("error", err)


                })
        }
        } catch (error) {
            writeToFileLog(`Repeat error: ${error}`)
        }
    }
    const getLogNotesStr = () => {
        return `${accountBalanceType === "Debit" ? `Debit Case ${activePaymentOption === "DebitOption1" ? "-Full-" : "-Partial-"}` : `Credit Case ${filteredBalance < prescriptionFee ? "2-Partial-" : "1-Full-"}`} Balance: ${Balance} - Prescription Fee: ${prescriptionFee} -web`
    }
    // ////// Paynow Poli Click /////////////////////
    const handlePoliSubmit = async () => {
        try {
        if (deductedAmount == "" || deductedAmount.length == 0) {
            openNotificationWithIcon('error', 'Your Request cannot be generated as the payment charges cannot be less than $1')
            setLoading(false)
            return;
        }
        else if (parseFloat(deductedAmount) < 1) {
            openNotificationWithIcon('error', 'Your Request cannot be generated as the payment charges cannot be less than $1')
            setLoading(false)
            return;
        }

        setLoading(true)

        //saving in indici temp payments



        getUserId().then((userInfo) => {
            const item = JSON.parse(userInfo);

            var myHeaders = new Headers();
            myHeaders.append("Token", item.token);
            myHeaders.append("Content-Type", "application/json");

            let notesTemp = getLogNotesStr()


            // $$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$
            let idString = '';

            const defaultGPID = Practise && Practise.length && Practise[0].resource.extension.find(o => o.url === 'GP ID')?.valueString ? `${Practise[0].resource.extension.find(o => o.url === 'GP ID')?.valueString}` : " N/A"
            selectedMedsList.map((item, i) => {
                idString = idString + item.resource?.id + ","
            })
            let uniqueIds = [...new Set(idString.split(',').map(id => id.trim()))];
            let result = uniqueIds.join(', ');
            idString = result.trim()
            let pIsUrgentPrescription = 0
            let pIsUrgentFax = 0
            let pIsFaxPrescription = 0

            if (ordertype === "Pick up" && isSelected) {
                pIsUrgentPrescription = 1

            }
            else if (ordertype === "Pharmacy" && isSelected) {
                pIsUrgentFax = 1
            }
            else if (ordertype === "Pick up" && !isSelected) {
                pIsFaxPrescription = 0
            }
            else if (ordertype === "Pharmacy" && !isSelected) {
                pIsFaxPrescription = 1
            }
            else {
                pIsUrgentPrescription = 0
                pIsUrgentFax = 0
                pIsFaxPrescription = 0
            }

            var repeatMedPayload;
            {
                isPickupSelected ?
                    repeatMedPayload = {
                        "PatientID": item.userid,
                        "MedicationID": idString,
                        "PickUpTypeID": 1,
                        "PharmacyID": 0,
                        "PharmacyName": "",
                        "IsUrgent": isSelected ? true : false,
                        "IsPreferredPharmacy": false,
                        "Notes": repeatMedNote,
                        "PharmacyLocationId": 0,
                        "ProviderId": providerLeaveChoice === "default" ? defaultGPID : providerOnLeaveSelect,      

                    }
                    :
                    repeatMedPayload = {
                        "PatientID": item.userid,
                        "MedicationID": idString,
                        "PickUpTypeID": 2,
                        "PharmacyID": Pharmacyname.id,
                        "PharmacyName": Pharmacyname.name,
                        "IsUrgent": isSelected ? true : false,
                        "IsPreferredPharmacy": isRepeatFavChecked,
                        "Notes": repeatMedNote,
                        "PharmacyLocationId": 0,
                        "IgnoreLeave": ignoreLeave,
                        "ProviderId": providerLeaveChoice === "default" ? defaultGPID : providerOnLeaveSelect,
                    }


            }
            repeatMedPayload = {
                ...repeatMedPayload,
                "IsInvoice": true,
                "InvoiceAmount": prescriptionFee,
                "PrescriptionFeeAmount": prescriptionFee,
                "PaymentLog": getLogNotesStr()

            }

            if (portalSetting.EnableRepeatRxInvoicing) {
                repeatMedPayload = {
                    ...repeatMedPayload,
                    "IsRxInvoicing": true,
                    "IsFaxPrescription": pIsFaxPrescription,
                    "IsUrgentPrescription": pIsUrgentPrescription,
                    "IsUrgentFax": pIsUrgentFax,
                }
            }
      // $$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$


            var raw = JSON.stringify({
                "Amount": calculateAmountToBePaid(),
                "PatientID": item.userid,
                "notes": 'Payment received via myindici',
                "lognotes": notesTemp,
                "callfrom": "web",
                "IsRxRequest": true,
                "abctick": Settings?.apiUrl?.replace("/api/", ""),
                "MerchantReference": "Poli Payment",
                "MerchantData": "patientId=" + item.userid + "&patientToken=" + item.token + "&IsAutoAdjustment=" + (activePaymentOption === "DebitOption1" ? "True" : "False") + "&repeat=" + JSON.stringify(repeatMedPayload),
                "invoiceDetailGrids": [],


            })
            var requestOptions = {
                method: 'POST',
                headers: myHeaders,
                body: raw,
                redirect: 'follow'
            };

            fetch(`${Settings.apiUrl}Patient/ProcessPoliRequest`, requestOptions)
                .then(response => response.json())
                .then(result => {
                    setLoading(false)
                    // console.log(JSON.parse(result))
                    let res = result
                    // if (JSON.parse(result)) {
                    //     res = JSON.parse(result)
                    // }
                    if (res.Success) {
                        window.open(res.NavigateURL, '_self');
                        // setNavigateUrlForPoli(res.NavigateURL)
                        // setPoliPaymentModalVisible(true)
                    } else {
                        if (res?.ErrorMessage && res?.ErrorMessage?.trim() !== "") {
                            openNotificationWithIcon("error", res.ErrorMessage ? res.ErrorMessage : "Payment was unsuccessful")

                        }
                        else if (res.error) {
                            openNotificationWithIcon("error", res.error.message ? res.error.message : "Payment was unsuccessful")
                        }
                        else if (typeof res === "string") {
                            openNotificationWithIcon("error", res)
                        }
                    }
                })
                .catch(error => openNotificationWithIcon('error', error));
        })
        } catch (error) {
            writeToFileLog(`Repeat error: ${error}`)
        }
    }
    useEffect(() => {
        if (Balance && typeof (Balance) === "string" && Balance !== "") {
            let filterOutBalance = parseFloat(Balance.split(" ")[0].replace("$", ""))
            setFilteredBalance(filterOutBalance)
            if (Balance?.toLowerCase()?.indexOf("dr") >= 0) {
                setAccountBalanceType("Debit")
                setActivePaymentOption("DebitOption1")
                setPayableFeeBeforeDeductions(filterOutBalance + prescriptionFee)

            }
            else if (Balance?.toLowerCase()?.indexOf("cr") >= 0) {
                setAccountBalanceType("Credit")
                setActivePaymentOption("Credit")
            }
            else if (Balance?.toLowerCase()?.indexOf("dr") < 0 && Balance?.toLowerCase()?.indexOf("cr") < 0) {
                setAccountBalanceType("Debit")
                setActivePaymentOption("DebitOption1")
                setPayableFeeBeforeDeductions(filterOutBalance + prescriptionFee)

            }
            else {
                setAccountBalanceType("Debit")
                setActivePaymentOption("DebitOption1")
                setPayableFeeBeforeDeductions(filterOutBalance + prescriptionFee)
            }

        } else {
            setAccountBalanceType(null)
            setFilteredBalance(0)
        }
    }, [Balance])
    const gridColumns = [
        {
            title: <input autoComplete="off" type="checkbox" className='darker-checkbox' onChange={() => { }} onClick={() => selectAllMeds()} checked={isSelectedBtnActive} />,
            dataIndex: "resource",
            // width: "50%",

            render: (item) => item?.extension?.find(o => o?.url === "IsNzulmActive")?.valueBoolean ? <input autoComplete="off" type="checkbox" className='darker-checkbox' onChange={() => { }} onClick={() => hanldeSlectedList(item)} disabled={item?.extension?.find(o => o?.url === "IsNzulmActive")?.valueBoolean === false} checked={selectedMedsList?.filter(selectedMed => {

                if (selectedMed.resource === item) {
                    return true
                }
            })?.length > 0} />
                :
                <>
                    <Tooltip title="This Medication has been marked as Obsolete. Please prescribe an alternative medicine">

                        <img src="assets/images/warning-sign.svg" width={20} />
                    </Tooltip>
                </>

        },
        {
            title: 'DATE',
            dataIndex: "resource",
            width: '200px',
            render: (item) => <p className='d-flex'><span className='w-100px'>{GetAppointmentDate(item?.hasOwnProperty('authoredOn') ? item.authoredOn : null) + ' ' + GetMonthName(item.hasOwnProperty('authoredOn') ? item.authoredOn : null) + ' ' + GetYear(item.hasOwnProperty('authoredOn') ? item.authoredOn : null)}</span> <img onClick={() => {
                setIsMedDetailModalOpen(true)
                setOpenedMedData({ "resource": item })
            }} src='assets/images/info-icon.svg' /></p>
        },
        {
            title: 'MEDICINE NAME',
            dataIndex: "resource",

            render: (item) => <p>{item?.hasOwnProperty('medicationCodeableConcept') ? capitalize(item?.medicationCodeableConcept?.coding[0]?.display) : 'N/A'}</p>

        },
        {
            title: 'DIRECTIONS',
            dataIndex: "resource",

            render: (item) => <p>{item?.hasOwnProperty('dosageInstruction') ? item.dosageInstruction[0].text : 'No'}</p>

        },
        {
            title: 'ACTION',
            dataIndex: "resource",

            render: (item) => (
                <div className='nzf-icon-container d-flex'  >

                    <div onClick={() => window.open(`http://webservice.nzf.org.nz/nzf?type=guidance&sctid=${item?.medicationCodeableConcept?.coding[0]?.code}#patie`, '_blank', 'noreferrer')} className='NZF-div-container' >
                        <div className='text-white'>NZF</div>
                    </div>
                </div>
            )

        },


    ];
    const handleStripePayment = async () => {

        try {

            if (parseFloat(deductedAmount) > 1 && parseFloat(deductedAmount) < 1) {
                openNotificationWithIcon('error', 'Your Request cannot be generated as the payment charges cannot be less than $1')
                setLoading(false)
                return;
            }
            getUserId().then((userInfo) => {
                const item = JSON.parse(userInfo);

                var myHeaders = new Headers();
                myHeaders.append("Token", item.token)
                myHeaders.append("Content-Type", "application/json")
                let [cardType, cardCharges] = cardtypename?.replace('%', '').split('-');

                let notesTemp = getLogNotesStr()
                // $$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$
                let idString = '';

                const defaultGPID = Practise && Practise.length && Practise[0].resource.extension.find(o => o.url === 'GP ID')?.valueString ? `${Practise[0].resource.extension.find(o => o.url === 'GP ID')?.valueString}` : " N/A"
                selectedMedsList.map((item, i) => {
                    idString = idString + item.resource?.id + ","
                })
                let uniqueIds = [...new Set(idString.split(',').map(id => id.trim()))];
                let result = uniqueIds.join(', ');
                idString = result?.trim()
                let pIsUrgentPrescription = 0
                let pIsUrgentFax = 0
                let pIsFaxPrescription = 0

                if (ordertype === "Pick up" && isSelected) {
                    pIsUrgentPrescription = 1

                }
                else if (ordertype === "Pharmacy" && isSelected) {
                    pIsUrgentFax = 1
                }
                else if (ordertype === "Pick up" && !isSelected) {
                    pIsFaxPrescription = 0
                }
                else if (ordertype === "Pharmacy" && !isSelected) {
                    pIsFaxPrescription = 1
                }
                else {
                    pIsUrgentPrescription = 0
                    pIsUrgentFax = 0
                    pIsFaxPrescription = 0
                }

                var repeatMedPayload;
                {
                    isPickupSelected ?
                        repeatMedPayload = {
                            "PatientID": item.userid,
                            "MedicationID": idString,
                            "PickUpTypeID": 1,
                            "PharmacyID": 0,
                            "PharmacyName": "",
                            "IsUrgent": isSelected ? true : false,
                            "IsPreferredPharmacy": false,
                            "Notes": repeatMedNote,
                            "PharmacyLocationId": 0,
                            "ProviderId": providerLeaveChoice === "default" ? defaultGPID : providerOnLeaveSelect,      

                        }
                        :
                        repeatMedPayload = {
                            "PatientID": item.userid,
                            "MedicationID": idString,
                            "PickUpTypeID": 2,
                            "PharmacyID": Pharmacyname.id,
                            "PharmacyName": Pharmacyname.name,
                            "IsUrgent": isSelected ? true : false,
                            "IsPreferredPharmacy": isRepeatFavChecked,
                            "Notes": repeatMedNote,
                            "PharmacyLocationId": 0,
                            "IgnoreLeave": ignoreLeave,
                            "ProviderId": providerLeaveChoice === "default" ? defaultGPID : providerOnLeaveSelect,
                        }


                }
                repeatMedPayload = {
                    ...repeatMedPayload,
                    "IsInvoice": true,
                    "InvoiceAmount": prescriptionFee,
                    "PrescriptionFeeAmount": prescriptionFee,
                    "PaymentLog": getLogNotesStr()

                }

                if (portalSetting && portalSetting?.EnableRepeatRxInvoicing) {
                    repeatMedPayload = {
                        ...repeatMedPayload,
                        "IsRxInvoicing": true,
                        "IsFaxPrescription": pIsFaxPrescription,
                        "IsUrgentPrescription": pIsUrgentPrescription,
                        "IsUrgentFax": pIsUrgentFax,
                    }
                }
                // $$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$

                let caughtEmail = false
                var raw = {
                    "Amount": Math.ceil(parseFloat(calculateAmountToBePaid()).toFixed(2) * 100),
                    "AmountNew": Math.ceil(parseFloat(calculateAmountToBePaid()).toFixed(2) * 100),
                    "PatientID": item.userid,
                    "CardType": cardType,
                    "cardCharges": cardCharges,
                    "notes": 'Payment received via myindici',
                    "invoiceDetailGrids": [],
                    "lognotes": notesTemp,
                    "paymenttype": "stripe",
                    "callfrom": "web",
                    "IsRxRequest": true,
                    "abctick": Settings.apiUrl,
                    "MerchantReference": "dsf",
                    "paymentMethodType": "card",
                    "MerchantData": "patientId=" + item.userid + "&patientToken=" + item.token + "&IsAutoAdjustment=" + (activePaymentOption === "DebitOption1" ? "True" : "False") + "&repeat=" + JSON.stringify(repeatMedPayload),
                    "UserEmail": item.email?.filter((email, index) => {
                        if (email && !caughtEmail) {
                            caughtEmail = true
                            return email
                        }
                    })[0]
                }
                var requestOptions = {
                    method: 'POST',
                    headers: myHeaders,
                    redirect: 'follow',
                    body: JSON.stringify(raw)
                };
                fetch(Settings.apiUrl + `Patient/CreateCheckoutSessionAndGetUrl`, requestOptions)
                    .then(response => response.json())
                    .then(result => {

                        if (result.IsSuccess) {
                            window.open(result.successurl, "_self");
                            // setNavigateUrlForPoli(result.successurl)
                            // setPoliPaymentModalVisible(true)
                            setLoading(false)
                        }
                        if (result.error) {
                            openNotificationWithIcon("error", result.error ? result.error : "Payment was unsuccessful")
                            setLoading(false)
                        }
                        if (typeof (result) === "string") {
                            openNotificationWithIcon("error", result)
                            setLoading(false)

                        }
                    })
                    .catch(error => {
                        openNotificationWithIcon("error", error)
                        setLoading(false)

                    });

            })
        } catch (error) {
            writeToFileLog(`Repeat error: ${error}`)
        }
    }
    const writeToFileLog = (payload11) => {
        try {

            fetch(Settings.apiUrl + '/Patient/WriteToFile?data=' + decodeURI(payload11 + `${new Date()}`) + '', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                }
            })
                .then(response => {
                    return response.json();
                })
                .then(data => {
                    console.log(data);
                    // Do something with the retrieved data
                })
                .catch(error => {
                    console.error('There was a problem with the fetch operation:', error);
                });
        }
        catch (e) {
            console.log(e)
        }
    }
    return (
        <div className="position-relative medication-inner-container">
            {
                isGetting && pageNumber === 1 && <div className='d-flex text-center justify-content-center mb-1 mt-2'><ActivityIndicator size="small" color="#00A1DE" /></div>
            }


            {
                typeof (AllMedeications) !== 'undefined' && AllMedeications.length ? (
                    <>
                        <div className='d-flex justify-content-between align-items-center bg-grey p-3 pl-2 px-2 flex-wrap mb-3'>



                            <div className='d-flex'>
                                <div
                                    className={"repeat-select-meds-btn " + (selectedMedsList && selectedMedsList.length >= 1 ? " active " : "")}
                                    onClick={() => {
                                        apiDispatcher(actionAPI.GetAccountBalanceAPI())
                                        setrepeadtedmodalVisible(true);

                                        if (ordertype === "Pharmacy") {

                                            let pIsUrgentPrescription = 0
                                            let pIsUrgentFax = 0
                                            let pIsFaxPrescription = 1
                                            getPrescriptionFeeFromApi(pIsUrgentPrescription, pIsUrgentFax, pIsFaxPrescription)
                                        } else if (ordertype === "Pick up") {

                                            let pIsUrgentPrescription = 0
                                            let pIsUrgentFax = 0
                                            let pIsFaxPrescription = 0
                                            getPrescriptionFeeFromApi(pIsUrgentPrescription, pIsUrgentFax, pIsFaxPrescription)
                                        }
                                    }}>
                                    <span className={"fs-4 " + (selectedMedsList && selectedMedsList.length >= 1 ? "text-white" : " text-light")}>{isMobile ? "Repeat Medication " : "Repeat Medication(s)"}</span>
                                </div>
                                {contextHolder}

                                <div
                                    className={`TouchableOpacityLongTerm ${selectedMedsList && selectedMedsList.length >= 1 ? 'TouchableOpacityLongTermCode1' : 'TouchableOpacityLongTermCode2 text-dark'}`}

                                    onClick={hanlePrintMeds}>
                                    <span className={"fs-4 " + (selectedMedsList && selectedMedsList.length >= 1 ? "text-white" : " text-light")}>Print</span>
                                </div>
                            </div>

                        </div>
                        <div id="longTermMedsScrollableDiv" className="longterm-med-scroll">
                            {/* {AllMedeications.map((item, index) => {

                                return <LongMedicationHandler
                                    key={index}
                                    options={() => hanldeSlectedList(item)}
                                    cardStyle={'card-header text-white bg-danger'}
                                    checkvalue={false}
                                    medDate={GetAppointmentDate(item?.resource?.hasOwnProperty('authoredOn') ? item.resource.authoredOn : null) + ' ' + GetMonthName(item.resource.hasOwnProperty('authoredOn') ? item.resource.authoredOn : null) + ' ' + GetYear(item.resource.hasOwnProperty('authoredOn') ? item.resource.authoredOn : null)}
                                    //medMonth={GetYear(item.resource.hasOwnProperty('authoredOn') ? item.resource.authoredOn : null)}
                                    medicationDetails={item.resource.hasOwnProperty('medicationCodeableConcept') ? capitalize(item.resource.medicationCodeableConcept.coding[0].display) : 'N/A'} //
                                    directions={item.resource.hasOwnProperty('dosageInstruction') ? item.resource.dosageInstruction[0].text : 'No'} // item.resource.dosageInstruction[0].text
                                    checking={item.resource.extension[1].valueString}
                                    quantity={item.resource.dispenseRequest.quantity.value + " " + (item.resource.extension[5] != undefined && item.resource.extension[5].valueString != undefined ? item.resource.extension[5].valueString : '')}
                                    endDate={item.resource.extension[4] != undefined ? (GetAppointmentDate(item.resource.extension[4].valueDateTime) + ' ' + GetMonthName(item.resource.extension[4].valueDateTime) + ' ' + GetYear(item.resource.extension[4].valueDateTime)) : "-"}
                                    startDate={GetAppointmentDate(item.resource.hasOwnProperty('authoredOn') ? item.resource.authoredOn : null) + ' ' + GetMonthName(item.resource.hasOwnProperty('authoredOn') ? item.resource.authoredOn : null) + ' ' + GetYear(item.resource.hasOwnProperty('authoredOn') ? item.resource.authoredOn : null)}
                                    isSelected={isSelectedBtnActive}
                                    setIsSelected={setIsSelectedBtnActive}
                                    prescribedBy={item.resource.extension[2].valueString}
                                    period={item.resource.extension[1].valueString}
                                    status={item.resource.extension[0].valueBoolean ? "Long Term" : "Recent"}
                                    practiceName={item.resource.extension[3].valueString}
                                    dose={item?.resource?.extension[4]?.valueString}
                                    medid={item?.resource?.medicationCodeableConcept?.coding[0]?.code}
                                />
                            })} */}
                            <InfiniteScroll
                                dataLength={longTermMedsDataList.length}
                                next={fetchMoreLongTermMedsDataFromAPI}
                                hasMore={longTermMedsDataList.length < totalRecords}
                                loader={<div className='d-flex justify-content-center my-2'><Spin /></div>}
                                scrollableTarget="longTermMedsScrollableDiv"
                            >
                            <Table
                                columns={gridColumns}
                                    rowClassName={(item) => item?.resource?.extension?.find(o => o.url === "IsNzulmActive")?.valueBoolean ? "" : "med-obsolete-bg"}
                                    dataSource={longTermMedsDataList}
                                pagination={false}
                                className='longterm-table'

                            />
                            </InfiniteScroll>
                            <Modal
                                open={isMedDetailModalOpen}
                                title={openedMedData && openedMedData.resource.hasOwnProperty('medicationCodeableConcept') ? capitalize(openedMedData.resource.medicationCodeableConcept.coding[0].display) : 'N/A'}
                                // onOk={() => setIsMedDetailModalOpen(false)}
                                cancelText="Close"
                                closable={false}
                                onCancel={() => setIsMedDetailModalOpen(false)}
                                okButtonProps={{
                                    style: {
                                        display: 'none',
                                    }
                                }}

                            >
                                {openedMedData && <div className="med-detail-body">
                                    <p className="text-primary fw-bold h6">Prescribed By: <span className="text-dark">{openedMedData.resource.extension[2].valueString}</span></p>
                                    <p className="text-primary fw-bold h6">Date Last Rx: <span className="text-dark">{GetAppointmentDate(openedMedData?.resource?.hasOwnProperty('authoredOn') ? openedMedData.resource.authoredOn : null) + ' ' + GetMonthName(openedMedData.resource.hasOwnProperty('authoredOn') ? openedMedData.resource.authoredOn : null) + ' ' + GetYear(openedMedData.resource.hasOwnProperty('authoredOn') ? openedMedData.resource.authoredOn : null)}</span></p>

                                    <p className="text-primary fw-bold h6">Directions: <span className="text-dark">{openedMedData.resource.hasOwnProperty('dosageInstruction') ? openedMedData.resource.dosageInstruction[0].text : 'No'}</span></p>
                                    <p className="text-primary fw-bold h6">Duration:  <span className="text-dark">{openedMedData.resource.extension[1].valueString}</span></p>
                                    <p className="text-primary fw-bold h6">Quantity: <span className="text-dark">{openedMedData.resource.dispenseRequest.quantity.value + " " + (openedMedData?.resource?.extension?.find(o => o.url === "Unit")?.valueString ? openedMedData?.resource?.extension?.find(o => o.url === "Unit")?.valueString : "")}</span></p>
                                    <p className="text-primary fw-bold h6">Dosage: <span className="text-dark">{openedMedData?.resource?.extension[4]?.valueString} dose(s)</span> </p>
                                    {/* <p className="text-primary fw-bold h6">Status: <span className="text-dark">{openedMedData.resource.extension[0].valueBoolean ? "Long Term" : "Recent"}</span></p>
                                    <p className="text-primary fw-bold h6">Practice Name: <span className="text-dark">{openedMedData.resource.extension[3].valueString}</span></p> */}
                                </div>}

                            </Modal>
                        </div>
                    </>
                ) : !isGetting &&
                <div className='alert alert-danger p-2 text-center'>No Records Found</div>


            }

            {/* Edit start */}
            <Modal
                title="Repeat Medication"
                centered
                maskClosable={false}
                closable={false}
                open={repeadtedmodalVisible}
                okText={(portalSetting && !portalSetting?.EnableRepeatRxInvoicing) || parseFloat(calculateAmountToBePaid()) === 0 ? "Submit Request" : 'Proceed to Payment'}
                onOk={() => {
                    if (ordertype.toLowerCase() === "select") {
                        openNotificationWithIcon('warning', "Please select Rx Type")

                    }
                    // else if (ordertype?.toLowerCase() == "pick up" && (portalSetting.IsOutstandingBalance && portalSetting.OutstandingBalance > 0)) {
                    //     openNotificationWithIcon('warning', "Cannot send pickup request patient has outstanding balance")
                    //     return
                    // }
                    else if (ordertype?.toLowerCase() != "pick up" && (Pharmacyname.id == "" || Pharmacyname.name == "Select Pharmacy")) {
                        openNotificationWithIcon('warning', "Please select Pharmacy")
                    }
                    else {

                        setLoading(true);
                        if (portalSetting && portalSetting?.EnableRepeatRxInvoicing && parseFloat(calculateAmountToBePaid()) > 0) {
                            validateRepeatRequestCall()
                            // if (paymentMethodType === "2") {

                            //     handleStripePayment()
                            // } else if (paymentMethodType === "1") {
                            //     handleWindcaveSubmit()
                            // } else {
                            //     handlePoliSubmit()
                            // }
                        } else {

                            RepeatedMedicationAdding()
                        }
                    }


                }}
                confirmLoading={loading}
                onCancel={() => {
                    handleRepeatMedCancel()
                }}
                afterClose={() => {
                    setLoading(false)
                }}
                className="profile-screen-modal"
                width={900}
                okButtonProps={portalSetting && portalSetting?.EnableRepeatRxInvoicing && paymentMethodType === "2" && {
                    style: {
                        display: "none"
                    }
                }}
                footer={(portalSetting && !portalSetting?.EnableRepeatRxInvoicing) || (accountBalanceType === "Credit" && deductedAmount <= filteredBalance) ?
                    [
                        <button disabled={loading || ordertype === "Select" || (ordertype === "Pharmacy" && Pharmacyname.id === "")} onClick={(e) => {
                            e.preventDefault()
                            if (ordertype.toLowerCase() === "select") {
                                openNotificationWithIcon('warning', "Please select a pickup type")

                            }

                            else if (ordertype?.toLowerCase() != "pick up" && (Pharmacyname.id == "" || Pharmacyname.name == "Select Pharmacy")) {
                                openNotificationWithIcon('warning', "Please select pharmacy")
                            }
                            else {
                                if ((portalSetting && portalSetting?.EnableRepeatRxInvoicing)) {

                                    validateRepeatRequestCall()
                                } else {
                                    setPaymentConfirmationModalOpen(true)
                                }


                            }
                        }} className={`btn btn-sm pay-with-stripe-btn-repeat-med btn-margin-left paynow-btn opacityone`} >
                            {loading ? <ActivityIndicator size="small" color="#fff" /> :
                                <div className="paynow-btn-div">
                                    <span className="upper">{(portalSetting && !portalSetting?.EnableRepeatRxInvoicing) || parseFloat(calculateAmountToBePaid()) === 0 ? "Submit Request" : 'Proceed to Payment'}</span>

                                </div>
                            }
                        </button>,
                        < Button className='height-40-px' onClick={() => handleRepeatMedCancel()}>
                            Cancel
                        </Button>
                    ]
                    : 
                        [
                        <button disabled={ordertype === "Select" || (ordertype === "Pharmacy" && Pharmacyname.id === "")} onClick={(e) => {
                            e.preventDefault()
                                if (ordertype.toLowerCase() === "select") {
                                    openNotificationWithIcon('warning', "Please select a pickup type")

                                }

                                else if (ordertype?.toLowerCase() != "pick up" && (Pharmacyname.id == "" || Pharmacyname.name == "Select Pharmacy")) {
                                    openNotificationWithIcon('warning', "Please select pharmacy")
                                }
                                else {
                                    if ((portalSetting && portalSetting?.EnableRepeatRxInvoicing)) {

                                        validateRepeatRequestCall()
                                    } else {
                                        setPaymentConfirmationModalOpen(true)
                                    }
                                }

                            }} className={`btn btn-sm pay-with-stripe-btn-repeat-med btn-margin-left paynow-btn opacityone`} >
                                {loading ? <ActivityIndicator size="small" color="#fff" /> :
                                    <div className="paynow-btn-div">
                                        <span className="upper">{(portalSetting && !portalSetting?.EnableRepeatRxInvoicing) || parseFloat(calculateAmountToBePaid()) === 0 ? "Submit Request" : 'Proceed to Payment'}</span>
                                    </div>
                                }
                            </button>,
                            < Button className='height-40-px' onClick={() => handleRepeatMedCancel()}>
                                Cancel
                            </Button>
                        ]}
            >
                <div className="row" >
                    <div className="col-md-12 mx-auto">

                        <div className="form-group mb-3 d-flex justify-content-between flex-wrap ">
                            <div className='med-repeat-balance-box d-flex'>
                                <div className='profile-img border-radius-50 d-flex align-items-center justify-content-center'><img src="assets/images/provider-icon.svg" /></div>
                                <div className=' ml-2'>

                                    <p className='m-0 text-align-justify text-secondary'>Provider</p>
                                    <p className='m-0 text-align-justify'><strong>
                                        {Practise && Practise.length && Practise[0].resource.extension.find(o => o.url === 'My GP')?.valueString ? ` ${Practise[0].resource.extension.find(o => o.url === 'My GP')?.valueString}` : " N/A"}</strong> </p>
                                </div>
                            </div>
                            <div className='med-repeat-balance-box d-flex'>
                                <div className='profile-img border-radius-10px d-flex align-items-center justify-content-center'><img src='assets/images/outstanding-icon.svg' /></div>
                                <div className='ml-2'>

                                    <p className='m-0 text-align-justify text-secondary'>Account Balance</p>
                                    <p className='m-0 text-align-justify'><strong>
                                        {Balance ? Balance : 0}</strong> </p>
                                </div>
                            </div>


                        </div>

                        <div className="form-group mb-0 p-3 LongTermdiv" >
                            {portalSetting && portalSetting.PortalRepeatMedSetting &&
                                <p className='text-justify'><div dangerouslySetInnerHTML={{
                                    __html:
                                        portalSetting.PortalRepeatMedSetting.replaceAll("\r\n", "<br>")
                                }} /> </p>}

                        </div>

                        <div className="d-flex flex-column">

                            <div className="form-group row mb-0 m-top-20 flex-1 mr-3">
                                <div className="col-md-9">
                                    <label htmlFor="validate-internet-ip-field">Pickup Medication <span className='text-danger'>*</span> </label>

                                    {/* {portalSetting && portalSetting.IsRxFax ?
                                        <Select
                                            getPopupContainer={(trigger) =>
                                                trigger.parentNode
                                            }
                                            defaultValue="Pick up"
                                            value={ordertype} className="col-md-12 row mb-0"
                                            onChange={handleChangetype}
                                            disabled={gettingPrescriptionFee}
                                        >
                                            <Option key={"0"} value="Select">Select</Option>

                                            <Option value="Pharmacy" key={"2"}>Collect From Pharmacy</Option>
                                            {portalSetting.ShowPickupTypeInRepeatRx ? <Option key={"1"} value="Pick up">Pickup Prescription from Practice</Option> : null}
                                        </Select> :
                                        <Select defaultValue="Pick up" value={ordertype} getPopupContainer={(trigger) =>
                                            trigger.parentNode
                                        } className="col-md-12 row mb-0" onChange={handleChangetype}>
                                            <Option key={"0"} value="Select">Select</Option>
                                            {portalSetting?.ShowPickupTypeInRepeatRx ?
                                                <Option key={"3"} value="Pick up">Pickup Prescription from Practice</Option>
                                                : null}
                                        </Select>} */}

                                    <Radio.Group
                                        defaultValue="Pick up"
                                        value={ordertype}
                                        className="col-md-12 row mb-0"
                                        onChange={handleChangetype}
                                        disabled={gettingPrescriptionFee}
                                    >
                                        {portalSetting && portalSetting.IsRxFax && <Radio value="Pharmacy">Collect Medication(s) From Pharmacy</Radio>}
                                        {portalSetting?.ShowPickupTypeInRepeatRx && <Radio value="Pick up">Pickup Prescription from Practice</Radio>}
                                    </Radio.Group>
                                </div>
                                <div className='row row-cols-1 row-cols-md-2'>
                            {portalSetting && portalSetting.IsRxUrgent &&
                                <div className="col-12 col-md-auto mb-0 m-top-20 pl-3 paddt-15">
                                            <Checkbox checked={isSelected} disabled={gettingPrescriptionFee} onChange={() => setIsSelected(!isSelected)}>Request script as urgent</Checkbox>
                                </div>
                            }
                        </div>
                                {isSelected && portalSetting.RepeatRxUrgentNotes && <span className='card-body full-width p-1 mt-2 pl-3'>{portalSetting.RepeatRxUrgentNotes ? portalSetting.RepeatRxUrgentNotes : ""}</span>}

                            </div>

                            {portalSetting && portalSetting.IsRxFax && ordertype == 'Pharmacy' &&
                                <div className="form-group row mb-0 m-top-20 flex-1 mr-3">
                                    <div className="col-md-9">
                                        <label htmlFor="validate-internet-ip-field">Select Pharmacy <span className='text-danger'>*</span></label>
                                        <Select
                                            placeholder="Select Pharmacy"
                                            getPopupContainer={(trigger) =>
                                                trigger.parentNode
                                            }
                                            showSearch
                                            // defaultValue={prefferedPharmacyID}
                                            value={Pharmacyname.id}
                                            id="mPharmacy" className="col-md-12 row mb-0 w-100" onChange={handleChange}
                                            filterOption={(input, option) => (option?.children?.toLowerCase() ?? '').includes(input?.toLowerCase())}>
                                            <Option value="" key="0">Select</Option>
                                            <OptGroup key={"group1"} label="Favorite Pharmacies" >
                                                {
                                                    Pharmacy && Pharmacy.length && Pharmacy?.slice()?.sort((a, b) => a.resource.name > b.resource.name ? 1 : -1)?.map((value, key) => {

                                                        if (value.resource.extension[2].valueBoolean)
                                                            return (
                                                                <Option key={value.resource.id} value={value.resource.extension[0].valueString}>{value.resource.extension[1].valueString}</Option>
                                                            )
                                                    })
                                                }
                                            </OptGroup>
                                            <OptGroup key={"group2"} label="Other Pharmacies">
                                            </OptGroup>

                                            {
                                                PharmaciesRegions.map((pharmRegion, index) =>
                                                    <OptGroup key={`region-group-${index}`} label={pharmRegion}>
                                                        {Pharmacy && Pharmacy.length && Pharmacy?.slice()?.sort((a, b) => a.resource.name > b.resource.name ? 1 : -1)?.map((value, key) =>

                                                            !value.resource.extension[2].valueBoolean && value.resource.extension[3].valueString == pharmRegion ?
                                                                <Option key={value.resource.id} value={value.resource.extension[0].valueString}>{value.resource.extension[1].valueString}</Option> : null
                                                        )
                                                        }
                                                    </OptGroup>
                                                )}

                                        </Select>

                                    </div>
<div className='paddt-15'>
                                    {portalSetting && portalSetting.IsRxFax &&
                                <>
                                    {ordertype == 'Pharmacy' &&
                                        <div className="col-12 col-md-auto">
                                            <div className="form-group row mb-0 m-top-20 pl-2">
                                                <Checkbox checked={isRepeatFavChecked} onChange={() => setIsRepeatFavChecked(!isRepeatFavChecked)} >My Preferred Pharmacy</Checkbox>
                                            </div>
                                        </div>
                                    }
                                </>}
</div>

                                </div>
                            }

                        </div>
                        <div className="form-group row mb-0 mt-3">
                            <div className="col-md-12">
                                <label htmlFor="validate-internet-ip-field">Any additional information</label>
                                <textarea autoComplete="off" id="mNotes" type="text" value={repeatMedNote} onChange={(e) => setRepeatMedNote(e.target.value)} maxLength={1000} className="form-control LongTermdTextArea" line="3" placeholder="Additional Information" ></textarea>
                            </div>

                        </div>

                        

                    </div>




                    {
                        portalSetting && portalSetting?.EnableRepeatRxInvoicing &&
                        <div className='col-md-12 bdr-top'>
                                {accountBalanceType === "Credit" || (filteredBalance === 0 && prescriptionFee === 0) ? null : <div className='d-flex'>
                                <img className='w-34' src='assets/images/payment-dollar-icon.svg' />
                                <p className='m-2 text-primary text-bolder fs-3 paymentheading'><strong>
                                    {/* {paymentMethodType == "1" ? "Windcave" : paymentMethodType == "2" ? "Stripe" : "Poli"} */}
                                        {filteredBalance === 0 ? "" : "Select"} Amount to Pay</strong></p>
                                </div>}
                            {
                                accountBalanceType === "Debit" ?
                                    <div className='debit-case-options m-0 row justify-content-between gap-3 mr-t-b-10'>
                                            <div className={'option option1 p-3 pl-4 d-flex align-items-center col-46' + (activePaymentOption === "DebitOption1" && (filteredBalance !== 0 || prescriptionFee !== 0) ? " active " : "")} onClick={() => {
                                                if (filteredBalance !== 0) {
                                                    setActivePaymentOption("DebitOption1");
                                                    setPayableFeeBeforeDeductions((filteredBalance + prescriptionFee).toFixed(2))
                                                }
                                        }}>
                                            {/* <div className='option-icon'><img src='assets/images/outstanding-icon.svg' /></div> */}
                                                {filteredBalance === 0 && prescriptionFee === 0 ? <img className='mr-2' src='assets/images/outstanding-icon.svg' /> : <input autoComplete="off" type='checkbox' className='customcheckbox' onChange={() => { }} checked={activePaymentOption === "DebitOption1"}></input>}
                                            <div className='option-text'>
                                                    <p className="m-0">Current Balance: <span className='font-size-13 font-weight-700'>{Balance}</span></p>

                                                    <p className="m-0 mb-2">Prescription Fee: <span className='font-size-13 font-weight-700'>${prescriptionFee}</span></p>
                                                    <p className="m-0 text-primary">Total: <span className='font-size-13 font-weight-700'>${(filteredBalance + prescriptionFee).toFixed(2)}</span></p>
                                            </div>
                                        </div>
                                            {filteredBalance === 0 ? null : <div className='orseperator'>OR</div>}
                                            {filteredBalance !== 0 && <div className={'option option2 p-3 pl-4 d-flex col-46 align-items-center  ' + (activePaymentOption === "DebitOption2" && (filteredBalance !== 0 || prescriptionFee !== 0) ? " active " : "")} onClick={() => {
                                                if (filteredBalance !== 0) {

                                                    setActivePaymentOption("DebitOption2");
                                                    setPayableFeeBeforeDeductions(prescriptionFee)
                                                }
                                        }}>

                                                {/* <div className='option-icon'><img src='assets/images/prescription-fee-icon.svg' /></div> */}
                                                {filteredBalance === 0 && prescriptionFee === 0 ? <img className='mr-2' src='assets/images/prescription-fee-icon.svg' /> : <input autoComplete="off" type='checkbox' className='customcheckbox' onChange={() => { }} checked={activePaymentOption === "DebitOption2"}></input>}

                                            <div className='option-text'>
                                                    <p className="m-0 mb-2">Prescription Fee Only: <span className='font-size-13 font-weight-700'>${prescriptionFee}</span></p>
                                            </div>
                                            </div>}


                                    </div> : accountBalanceType === "Credit" ?
                                        <div className='credit-case-options m-3 row justify-content-between gap-3'>
                                            {filteredBalance < prescriptionFee ?
                                                <>
                                                        <div className={'option option3 p-3 pl-4 d-flex col-5 align-items-center active '}>
                                                        <div className='option-icon'><img src='assets/images/outstanding-icon.svg' /></div>
                                                        <div className='option-text'>
                                                                <p className="m-0">Amount Due: <span className='font-size-13 font-weight-700'>${(prescriptionFee - filteredBalance)?.toFixed(2)}</span></p>


                                                        </div>
                                                    </div>
                                                    <div className={'option option5 p-3 pl-4 d-flex col-5 align-items-center '}>
                                                        <div className='option-icon'><img src='assets/images/prescription-fee-icon.svg' /></div>
                                                        <div className='option-text'>
                                                                <p className="m-0 mb-2">Prescription Fee: <span className='font-size-13 font-weight-700'>${prescriptionFee}</span></p>
                                                        </div>
                                                    </div>
                                                </>
                                                :
                                                    <div className={'option option5 p-3 pl-4 d-flex col-5 align-items-center active '}>
                                                        <div className='option-icon'><img src='assets/images/prescription-fee-icon.svg' /></div>
                                                    <div className='option-text'>
                                                            <p className="m-0">Current Balance: <span className='font-size-12 font-weight-700'>{Balance}</span></p>
                                                            <p className="m-0 mb-2">Prescription Fee: <span className='font-size-12 font-weight-700'>${prescriptionFee}</span></p>
                                                            <p className="m-0 text-primary">Remaining Balance: <span className='font-size-12 font-weight-700'>${(filteredBalance - prescriptionFee).toFixed(2)}</span></p>

                                                    </div>
                                                </div>
                                            }
                                        </div>
                                        : <div className='other-case-options'></div>
                            }
                            {accountBalanceType === "Credit" ?
                                prescriptionFee > filteredBalance ?
                                        <p className=' m-2 mx-4 text-c0lor-green'><strong>Your account has a credit balance of ${filteredBalance} amount. This will be applied against the prescription fees, leaving ${(prescriptionFee - filteredBalance)?.toFixed(2)} amount due.</strong></p>
                                    :
                                    <p className=' m-2 mx-4 text-c0lor-green'><strong>Your account has a credit balance; no payment is needed. Prescription fee will be adjusted from the credit.</strong></p>
                                :
                                null}
                            {((accountBalanceType === "Credit" && prescriptionFee > filteredBalance) || accountBalanceType === "Debit" || accountBalanceType === "Other") && <div className='row row-cols-2 full-width-more'>
                                    {/* <div className="form-item col-md-6 col-sm-6">

                                    <label className="mb-0">Amount</label>
                                    <div className="form-control-container d-flex flex-column align-items-start">

                                        <Input autoComplete="off"
                                            placeholder="Amount"
                                            className='h-34px'
                                            disabled
                                            value={deductedAmount}
                                            type="number"

                                            name="amountErr"
                                        />

                                    </div>
                                </div> */}


                                    {/* {portalSetting?.IsCardTypeConfifiguration && paymentMethodType === "2" && <div className="form-item col-md-6 col-sm-6">

                                    <label className="mb-0">Card Type</label>
                                    <div className="form-control-container">

                                        {lookupData ?

                                            <Select
                                                onChange={(e) => {
                                                    setCardTypeName(e);
                                                    setCardTypeErrMsg("");
                                                    setCardTypeNotMatchErrMsg("");
                                                }}
                                                value={cardtypename}
                                                className='w-100'
                                                // disabled
                                                options={
                                                    [{
                                                        value: "",
                                                        label: "Select"
                                                    }].concat(

                                                        lookupData.map(item => {
                                                            return {
                                                                value: item.CardFee,
                                                                label: item.CardFee,
                                                            }
                                                        })
                                                    )
                                                }
                                            /> : <Spin />
                                        }

                                    </div>
                                    <p className="text-danger">{cardTypeErrMsg}</p>

                                </div>} */}


                                {/* <div className="form-item col-md-6 col-sm-6">

                                    <label className="mb-0">Notes</label>
                                    <div className="form-control-container">
                                        <textarea
                                            name
                                            id
                                            placeholder="Notes"
                                            className="form-control"
                                            rows={1}

                                            defaultValue={""}
                                            value={medRepeatPaymentNotes}
                                            onChange={(e) => setMedRepeatPaymentNotes(e.target.value)}
                                        />
                                    </div>
                                </div> */}
                            </div>}
                        </div>
                    }

                </div>
                {/* </div> */}
            </Modal>
            <Modal
                title={(portalSetting && !portalSetting?.EnableRepeatRxInvoicing) || (accountBalanceType === "Credit" && prescriptionFee <= filteredBalance) || (parseFloat(deductedAmount) === 0) ? "Comfirmation" : "Payment Confirmation"}
                open={paymentConfirmationModalOpen}
                onOk={() => {
                    if ((portalSetting && !portalSetting?.EnableRepeatRxInvoicing) || parseFloat(calculateAmountToBePaid()) === 0) {
                        const isInvoicingBit = (portalSetting && !portalSetting?.EnableRepeatRxInvoicing) ? false : true
                        const invoiceAmountBit = (portalSetting && !portalSetting?.EnableRepeatRxInvoicing) ? 0 : prescriptionFee
                        RepeatedMedicationAdding(ignoreLeave, isInvoicingBit, invoiceAmountBit)
                        setPaymentConfirmationModalOpen(false)
                    } else {
                        let isAmountValidated = handlePayNowValidations();
                        if (isAmountValidated) {
                            return
                        }

                        if (paymentMethodType === "2") {

                            handleStripePayment(ignoreLeave)
                        } else if (paymentMethodType === "1") {
                            handleWindcaveSubmit(ignoreLeave)
                        } else {
                            handlePoliSubmit(ignoreLeave)
                        }
                        setPaymentConfirmationModalOpen(false)
                    }


                }}
                onCancel={() => {
                    setPaymentConfirmationModalOpen(false);
                    setLoading(false)
                }}
                okText="Proceed"
            >
                {(portalSetting && !portalSetting?.EnableRepeatRxInvoicing) || parseFloat(calculateAmountToBePaid()) === 0 ? "Are you sure you want to repeat request"
                    :
                    "Are you sure you want to proceed with payment, you will not be able to cancel this request once the payment is processed. You will need to call the practice for cancellation or any script changes."}
            </Modal>
            <Modal
                title="Poli Payment"
                open={poliPaymentModalVisible}
                onCancel={() => setPoliPaymentModalVisible(false)}
                maskClosable={false}
                okButtonProps={{ style: { display: 'none' } }}
                cancelText="Close"
                afterClose={() => {
                    apiDispatcher(actionAPI.GetAccountBalanceAPI())
                    setLoading(false)
                }}
                width={700}>
                <iframe title="Poli Payment" src={navigateUrlForPoli} width={"100%"} height="700px"></iframe>
            </Modal>
            {/* End Modal*/}

            <Modal
                title="Request Confirmation"
                open={newModalVissible}
                onOk={() => {
                    apiDispatcher(actionAPI.GetAppSettingAPI())
                    setNewModalVissible(false)
                    // setLoading(false)

                }}
                cancelText="Close"
                afterClose={() => {
                    setRepeatMedNote("")
                    setrepeadtedmodalVisible(false)
                    handleRepeatMedCancel()
                    if (mesagestate?.indexOf("Your payment is successful") === 0) {
                        localStorage.setItem("redirectedOnce", true)
                        props.setActiveTabKey("3")
                    }
                }}
                onCancel={() => {

                    setNewModalVissible(false);
                    handleRepeatMedCancel(true);
                }}
                cancelButtonProps={{ style: { display: 'none' } }}
            >


                <span>{mesagestate}</span>

            </Modal>
            {/* When provider is on leave */}
            <Modal
                title="Medication"
                open={providerOnLeaveModalVissible}
                onOk={() => {
                    setProviderOnLeaveModalVissible(false)
                    if (providerOnLeaveApiData.length > 0) {
                        if (portalSetting && portalSetting?.EnableRepeatRxInvoicing && deductedAmount > 0) {
                            // validateRepeatRequestCall(true)
                            setPaymentConfirmationModalOpen(true)
                        } else {
                            RepeatedMedicationAdding(true)
                        }
                    }
                    setLoading(false)

                }}
                afterClose={() => {
                    // setProviderOnLeaveSelect()
                    // setProviderLeaveChoice("default")
                }}
                cancelText="Close"
                onCancel={() => {
                    setProviderOnLeaveModalVissible(false);
                    setProviderLeaveChoice("default")
                    setProviderOnLeaveSelect()
                    setIgnoreLeave(false)

                    setLoading(false)
                }}
            >
                {providerOnLeaveApiData.length > 0 ? <>
                    <p><b>Your Provider is on Leave</b></p>
                    <p>How would you like to proceed with repeat medication request</p>

                    <Radio.Group className='w-100' value={providerLeaveChoice} onChange={(e) => setProviderLeaveChoice(e.target.value)}>

                        <Radio className='w-100' value={"other"}>
                            <p className='mx-2'>Select Other Provider:</p>

                        </Radio>
                        <Select
                            getPopupContainer={(trigger) =>
                                trigger.parentNode
                            }
                            allowClear
                            className='w-100'
                            showSearch
                            disabled={providerLeaveChoice !== "other"}
                            value={providerOnLeaveSelect}
                            onChange={(e) => {
                                setProviderOnLeaveSelect(e)
                            }}

                        >
                            {providerOnLeaveApiData.length && providerOnLeaveApiData.map((state, index) =>
                                <Option key={index} value={state.ProviderID}>{state.ProviderName}</Option>
                            )}

                        </Select>
                        <Radio className='mt-3 w-100' value={"default"}>Send request to default provider
                        </Radio>
                    </Radio.Group>
                </> : <>
                    <p className=''><b>Your provider is on leave. No other provider is available</b></p>
                    <p>Please contact practice for further assistance</p>
                </>}
            </Modal>
            <Modal
                title="Print Selected Medication(s)"
                open={printtMedsModal}
                onOk={() => setIsPrintActive(true)}
                onCancel={() => setPrintMedsModal(false)}
                okText="Print"
                width={1000}
            >
                <div ref={componentRef}>
                    <PrintOrganizationHeader patientProfile={Profile} organizationData={Practise} isPrintActive={isPrintActive} />
                    <table className="print-med-table w-100 longterm-print-table">
                        <tr className="print-med-table-row mb-2">
                            <th className="print-med-table-head" id="head-1" >
                                S.No.
                            </th>
                            <th className="print-med-table-head">
                                Medicine
                            </th>
                            <th className="print-med-table-head">
                                Prescribed On
                            </th>
                        </tr>
                        {selectedMedsList?.map((med, index) =>
                            <>
                                <tr className="print-med-table-row mb-2">
                                    <td>
                                        {index + 1}
                                    </td>
                                    <td>
                                        <p className="m-0"><b>{med?.resource?.medicationCodeableConcept && med?.resource?.medicationCodeableConcept?.coding[0]?.display}</b></p>
                                        <p className="m-0">Sig.: {med?.resource?.dosageInstruction && med?.resource?.dosageInstruction[0]?.text}</p>
                                        <p className="m-0">Duration: {med?.resource?.extension[1]?.valueString}</p>
                                    </td>
                                    <td>
                                        <span>{GetAppointmentDate(med?.resource?.hasOwnProperty('authoredOn') ? med?.resource?.authoredOn : null) + '/' + GetMonthName(med?.resource?.hasOwnProperty('authoredOn') ? med?.resource?.authoredOn : null) + '/' + GetYear(med?.resource?.hasOwnProperty('authoredOn') ? med?.resource?.authoredOn : null)}</span>
                                    </td>
                                </tr>
                            </>
                        )}
                    </table>
                </div>
            </Modal>
        </div>
    );
}


function mapStateToProps(state) {
    return {
        AllMedeications: state.AllMedeications,
        Pharmacy: state.Pharmacy,
        Profile: state.Profile,
        Practise: state.Practise,
        ApplicationSettings: state.ApplicationSettings

    }
}

function mapDispatchToProps(dispatch) {
    return {
        getMed: (pageSize, pageNumber) => dispatch(fetchAllMedsFromApi(pageSize, pageNumber)),
        getAppSetting: () => dispatch(fetchApplicationSettingFromApi()),
        getPharmacy: () => dispatch(fetchPharmcyFromApi()),

    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(LongTermMed);

// export default AllMedications
